// Function Component that will render the whole website
import "./index.css";
import RouterSystem from "./routes";

function App() {
  return (
    <>
      <RouterSystem />
    </>
  );
}

export default App;
